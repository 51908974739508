import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "guides-v1"
    }}>{`Guides v1`}</h1>
    <h3 {...{
      "id": "deprecation-note"
    }}>{`Deprecation Note`}</h3>
    <p>{`The Offers API v1 is being replaced by the Offers API v2. Deprecation date has been set to `}<inlineCode parentName="p">{`1st of October 2023`}</inlineCode>{`.
Developers of existing clients should start migrating to the new version.
Developers of new clients are advised to start out with the v2 API.`}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-to-use-the-offers-api"
        }}>{`How to use the offers API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#find-trip-patterns-and-update-offers-for-trip-pattern"
        }}>{`Find trip-patterns and update offers for trip-pattern`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#intention-and-usage"
            }}>{`Intention and usage`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#input-types"
            }}>{`Input types`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-offers-for-trip"
        }}>{`Create offers for trip`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#intention-and-usage"
            }}>{`Intention and usage`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#input-types"
            }}>{`Input types`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-/search/stop-places"
        }}>{`Using /search/stop-places`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#intention-and-usage"
            }}>{`Intention and usage`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#input-types"
            }}>{`Input types`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-offers-without-a-trip"
        }}>{`Create offers without a trip`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#intention-and-usage"
            }}>{`Intention and usage`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#input-types"
            }}>{`Input types`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#requested-parameters"
            }}>{`Requested parameters`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#recommendation"
        }}>{`Recommendation`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#introduction"
            }}>{`Introduction`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#background"
            }}>{`Background`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#classification-of-products"
            }}>{`Classification of Products`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#recommendations-of-offer-combinations"
            }}>{`Recommendations of Offer Combinations`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#basic-recommendations"
            }}>{`Basic Recommendations`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#layered-recommendations"
            }}>{`Layered Recommendations`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#multidimensional-recommendations"
            }}>{`Multidimensional Recommendations`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#tagspec-deprecated-(supported-until-november-1.-2019)"
        }}>{`TagSpec - Deprecated (supported until November 1. 2019)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#introduction"
            }}>{`Introduction`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#the-different-tagging-options"
            }}>{`The different tagging options`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-include-offers-without-a-tag"
            }}>{`How to include offers without a tag`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-structure-of-an-offer"
        }}>{`The structure of an offer`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#model"
            }}>{`Model`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#pricing"
            }}>{`Pricing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#multiple-legs"
            }}>{`Multiple legs`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#optional-products"
            }}>{`Optional products`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#supplement-products"
        }}>{`Supplement products`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#the-price-of-a-supplement-product"
            }}>{`The price of a supplement product`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#common-supplement-products-and-classification-of-supplement-products"
            }}>{`Common supplement products and classification of supplement products`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#capacity-for-supplement-products"
            }}>{`Capacity for supplement products`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#supplement-products-that-apply-to-more-than-one-leg"
            }}>{`Supplement products that apply to more than one leg`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#filtering-supplementproducts"
            }}>{`Filtering SupplementProducts`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#selecting-supplement-products"
            }}>{`Selecting supplement products`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#seating-capacity"
        }}>{`Seating capacity`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#quota"
        }}>{`Quota`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#entitlement-products"
        }}>{`Entitlement products`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#tickets-for-extended-journeys"
        }}>{`Tickets for extended journeys`}</a></li>
    </ul>
    <h2 {...{
      "id": "how-to-use-the-offers-api"
    }}>{`How to use the offers API`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Offers API provides endpoints for generating and retrieving offers for a combination of origin and destination. Offers API typically gathers more information about the departure and sends a request to the Products API, where the offer is generated. In addition Offers API is responsible for caching the offers which Products API produces, these offers are cached for 30 minutes.`}</p>
    <p>{`Offers API also provides the possibility for consumers to use OTP (the journey planner) directly, and then generate offers at a later stage. The offer respons structure will be identical, and the same offers will be generated for any given trip.`}</p>
    <p>{`Some products are not directly connected to any given journey, for instance period tickets. We have a separate API which can generate offers for these products.`}</p>
    <h2 {...{
      "id": "find-trip-patterns-and-update-offers-for-trip-pattern"
    }}>{`Find trip-patterns and update offers for trip-pattern`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <h3 {...{
      "id": "intention-and-usage"
    }}>{`Intention and usage`}</h3>
    <p>{`Find trip-patterns will send a query to the journey planner and get back possible itineraries between two places. At this point the offers API will be responsible for caching the trip-patterns, but it will not have any information regarding the travellers. For optimization purposes we will immediately begin to generate offers for all the trip-patterns, using one adult as our list of travellers. This enables the consumers to fetch this offer as a starting point for the costumers.`}</p>
    <p>{`If the list of travellers is anything other than one adult the next part is to update offers for trip-pattern, which will create new offers for the tip-pattern for a specified list of travellers. The new offers will also be cached, and will be connected to the trip-pattern, this ensures that the new offers will be returned from the Fetch trip-pattern and offers API.`}</p>
    <h3 {...{
      "id": "input-types"
    }}>{`Input types`}</h3>
    <h4 {...{
      "id": "traveller"
    }}>{`Traveller`}</h4>
    <p>{`This field is used in order to help the consumers select the correct userProfile for any traveller. The most common way to find the correct userProfile is to use the userType Enum defined in Netex. It is also possible to find the correct userProfile using age or userProfileRefs`}</p>
    <p>{`The userType Enum have the following values:`}</p>
    <ul>
      <li parentName="ul">{`Adult`}</li>
      <li parentName="ul">{`Senior`}</li>
      <li parentName="ul">{`Child`}</li>
      <li parentName="ul">{`Infant`}</li>
      <li parentName="ul">{`Youth`}</li>
      <li parentName="ul">{`Military`}</li>
      <li parentName="ul">{`Student`}</li>
      <li parentName="ul">{`Anyone`}</li>
    </ul>
    <h2 {...{
      "id": "create-offers-for-trip"
    }}>{`Create offers for trip`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <h3 {...{
      "id": "intention-and-usage-1"
    }}>{`Intention and usage`}</h3>
    <p>{`Some of our consumers prefer to use the journey planner directly, and then to use Create offers for trip API to generate offers. In order to do this, the offers API will need information about the trip, tripPatterns, travellers and tagSpec. The resulting offers will be identical as to use Find trip-patterns and update offers for trip-pattern.`}</p>
    <h3 {...{
      "id": "input-types-1"
    }}>{`Input types`}</h3>
    <h4 {...{
      "id": "trip"
    }}>{`Trip`}</h4>
    <p>{`The trips is a way for us to know the costumers origin and destination with no regard to the different travel journeys.`}</p>
    <h4 {...{
      "id": "trippattern"
    }}>{`TripPattern`}</h4>
    <p>{`The tripPatterns is a list of legs constituting a suggested sequence of rides and links for a specific trip. The tripPatterns serve as our basis for generating offers.`}</p>
    <h4 {...{
      "id": "traveller-1"
    }}>{`Traveller`}</h4>
    <p>{`This field is used in order to help the consumers select the correct userProfile for any traveller. The most common way to find the correct userProfile is to use the userType Enum defined in Netex. It is also possible to find the correct userProfile using age or userProfileRefs`}</p>
    <p>{`The userType Enum have the following values:`}</p>
    <ul>
      <li parentName="ul">{`Adult`}</li>
      <li parentName="ul">{`Senior`}</li>
      <li parentName="ul">{`Child`}</li>
      <li parentName="ul">{`Infant`}</li>
      <li parentName="ul">{`Youth`}</li>
      <li parentName="ul">{`Military`}</li>
      <li parentName="ul">{`Student`}</li>
      <li parentName="ul">{`Anyone`}</li>
    </ul>
    <h4 {...{
      "id": "tagspec"
    }}>{`TagSpec`}</h4>
    <p>{`We have covered TagSpec as a separate chapter further down in this guide.`}</p>
    <h2 {...{
      "id": "using-searchstop-places"
    }}>{`Using /search/stop-places`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <h3 {...{
      "id": "intention-and-usage-2"
    }}>{`Intention and usage`}</h3>
    <p>{`The search/stop-places endpoint is used to search for offers that are not limited to specific departures such as travel passes and zone based tickets. The endpoint requires only a from- and to-stop-place, and uses those to find operators that offer transport between those stop places by searching for lines that contains both stop places. The endpoint also require a date-time is used to find fare products and prices that are valid for a specific date. Usually the current time should be used as period-tickets are often activated directly after purchase, however any date can be used if the user already knows that the ticket should be activated at that time. Note that it is not relevant for the result whether or not there exists a planned service journey for the given date.`}</p>
    <p>{`NOTE: Currently the endpoint only works with train-stations`}</p>
    <h3 {...{
      "id": "input-types-2"
    }}>{`Input types`}</h3>
    <p>{`The SearchStopPlaceRequest allows the user to specify the from- and to-stop-place, the date and a list of travellers. The Traveler-type is described in detail in a separate section above. The request also contains a flag called excludeSingleTripOffers. If true then only offers valid for a day or more are returned.`}</p>
    <h2 {...{
      "id": "create-offers-without-a-trip"
    }}>{`Create offers without a trip`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <h3 {...{
      "id": "intention-and-usage-3"
    }}>{`Intention and usage`}</h3>
    <p>{`The /search endpoint is meant to make it possible to get an offer for a product without having to specify a trip or any information about the travellers. This is useful for offering period tickets and tickets that dosent require reserving a seat, especially for local travellers that are familiar with the products and the user profiles in the area.`}</p>
    <p>{`To create an offer using the /search enpoint the client should specify a product-id and the id of the product-parameters that should be used for pricing (refer to the section about offer stucture for a further explanation on product-parameters). If the client has made a choice for every configurable parameter on the product they will get back an offer with those parameters selected.`}</p>
    <p>{`In some cases it can be useful to let the API choose some of the parameters, for example if the user shouldn't be able to select which user profile they get or if there is an intended trip involved. It is possible through the TravellerGroup and JourneySpec types to specify enough information abouth the trip or the travellers so that the API can select the parameters that are valid. To clearify the difference between this and letting the customer decide which parameters they want consider the following example: A local city product is priced based on how many zones the traveller needs to cross. This is modelled using interval-parameters with different prices attached to them, meaning that in order to get an offer for 3 zones the interval 3 -> 4 zones must be chosen. The different options in the /search enpoint means that the client have two different ways of offering this product to their customers. One would be to fetch all the intervals from the API, present them in a drop-down-menu to the customer and then use the requestedParameters-field to make an offer for the interval that the customer chose. Another approach would be to let the customer select a start- and end-zone and provide those zones to the API using a JourneySpec. In that case the API will find the number of zones in the journey and make an offer with the matching interval.`}</p>
    <p>{`The client will receive the ids for all parameters that needs to be chosen from a separate endpoint (TBD). If the client lets the customer choose between the product-parameters directly then caching the offers would be a good idea since there often aren't that many possible combinations of parameters. Lets say there is a product that requires a choice between 3 user profiles and 4 zone intervals (all intervals above 4 zones cost the same). Then there are only 12 different offers that can be made so it should be easy to cache them all and create a mapping from each combination of parameters to the matching offer. If the client however opts to let the customer chose start- and end-zone as described earlier there will be many more diferent keys so the cache will be much bigger even though the number of different possible offers is still the same.`}</p>
    <h3 {...{
      "id": "input-types-3"
    }}>{`Input types`}</h3>
    <p>{`The ProductQuery is the main input parameter of this endpoint. It consists of 3 optional parts`}</p>
    <h4 {...{
      "id": "productspec"
    }}>{`ProductSpec`}</h4>
    <p>{`This is used to specify what/which product(s) you want offers for. To get an offer for a product provide the id of that product in a product spec. To get offers for mulitple products at once create several product specs.`}</p>
    <h4 {...{
      "id": "travellergroup"
    }}>{`TravellerGroup`}</h4>
    <p>{`This is used to specify information about the travellers. A traveller group consists of a TravellerSpec that describe common properties amongst the travellers in the group an a numberOfTravellers which says how many travellers there are in the group. Its up to the client to create groups that are useful for their needs. For instance for smaller groups it may be sensible do divide the travellers into adults, children, students etc, however for large groups it may be more practical to just create one big group. The caveat is that some products or discounts require specific combinations of travellers (for example a family discount) and if the client havent grouped the travellers accordigly they will not receive offers for those products/discounts. A way to avoid the whole problem of dividing the travellers into groups is to create a separate group for each traveller. Note that this may lead to performance issues if there are many travelers.`}</p>
    <h4 {...{
      "id": "journeyspec"
    }}>{`JourneySpec`}</h4>
    <p>{`This describes properties of a leg like its start and stop place, the operator for the leg and the line. To describe a trip-pattern consisting of several legs, a list of journey specs can be used.`}</p>
    <h3 {...{
      "id": "requested-parameters"
    }}>{`Requested parameters`}</h3>
    <p>{`The requested parameters field lets the client specify which product-parameters should be used for the offer. It is structured as a map where the keys are strings and the values are lists of strings. The key should be the id of the element in the product-structure that holds the parameters in the choice. The client will receive the ids of the elements that requires a choice along with the ids of all the parameters in the choice from a separate endpoint (TBD). The value should be the id(s) of the parameter(s) that the client/customer wishes to receive an offer for. If several paramer-ids are given there will be created a separate offer for each one. That can be useful if the client wishes to get offers for adults and children simultaneously.`}</p>
    <h2 {...{
      "id": "recommendation"
    }}>{`Recommendation`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <h3 {...{
      "id": "introduction"
    }}>{`Introduction`}</h3>
    <p>{`Recommendations is a feature that aims to recommend which offers you need to buy for various situations. Currently we support three kinds of recommendations;`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Basic:`}</strong>{` based on three types of commercial conditions(described in more detail later).`}</li>
      <li parentName="ol"><strong parentName="li">{`Layered:`}</strong>{` has two added dimensions, when compared to the basic recommendation. The layered recommendation gives recommendations just like the basic recommendation, but it does it for each combination of service journeys and for each commercial condition there is an extra layer where the clients can specify which fare classes to get recommendations for per commercial condition.`}</li>
      <li parentName="ol"><strong parentName="li">{`Multidimensional:`}</strong>{` has three dimensions of which the recommendations are structured. It looks like the layered recommendations, however a added dimension is added for separating different types of accommodation facilities(sleeper, recliner, regular seat, etc.)`}</li>
    </ol>
    <h3 {...{
      "id": "background"
    }}>{`Background`}</h3>
    <p>{`Offers produces one offer for each configuration of a `}<em parentName="p">{`PreassigneFareProduct`}</em>{` which are relevant for each ride and traveler. An offer can cover multiple travellers, however the amount of offers can still become very high if there are several travellers and multiple operators.`}</p>
    <h3 {...{
      "id": "classification-of-products"
    }}>{`Classification of Products`}</h3>
    <p>{`In order to attack the problem of figuring out which offers to use from the large set of offers, our strategy is to support classification of products. This makes it easier to compare various properties i.e accommodation facilities(sleeper, reclining seat, etc.), conditions(refundability, exchangeability) and price. The criteria for these properties are defined in Netex and such classifications will be consistent across different operators.`}</p>
    <p>{`Properties in the offer-response used for classification and comparison are:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`FareClass`}</em>{`: FareClassEnum `}<strong parentName="li">{`[economy, standard, premium, etc.]`}</strong></li>
      <li parentName="ul"><em parentName="li">{`AcommodationFacility`}</em>{`: AcommodationFacilityEnum `}<strong parentName="li">{`[seating, sleeper, familyCompartment, etc]`}</strong></li>
      <li parentName="ul"><em parentName="li">{`CommercialConditionSummary`}</em>
        <ul parentName="li">
          <li parentName="ul">{`isRefundable: Boolean`}</li>
          <li parentName="ul">{`isExchangeable: Boolean`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Products are categorized as either `}<em parentName="p">{`Flexible`}</em>{`, `}<em parentName="p">{`SemiFlexible`}</em>{` or `}<em parentName="p">{`NonFlexible`}</em>{`, the table below describes how a product is classified.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`isRefundable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`isExchangeable`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Flexible`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TRUE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TRUE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SemiFlexible`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TRUE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FALSE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FALSE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TRUE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`NonFlexible`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FALSE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FALSE`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Flexible tickets(full price) allows the traveller to change departure and gives full refund.`}</li>
      <li parentName="ul">{`SemiFlexible tickets are usually a cheaper option, however for these tickets one can either refund `}<strong parentName="li">{`or`}</strong>{` change departure, depending on the commercial condition summary of the product.`}</li>
      <li parentName="ul">{`NonFlexible tickets are usually the cheapest option, but these tickets can't be refunded and the departure can't be changed.`}</li>
    </ul>
    <h3 {...{
      "id": "recommendations-of-offer-combinations"
    }}>{`Recommendations of Offer Combinations`}</h3>
    <p>{`When a traveller wants to buy tickets for a trip, the commercial conditions of the ticket are important(wheather or not it can be refunded or exchanged). In order to make it easier for clients to present good suggestions on which offers cover a trip with several rides/legs and travellers, the API will recommend combinations of offers to buy for each category introduced above and in addition, for each service journey combination, for each fare class per category for layered recommendations and in addition for each type of accommodation facility for multidimensional recommendations. The recommendations should be consistent, such that the offers seems identical for the travellers regardless of which client it uses.`}</p>
    <p>{`In some cases there will be many products that are sold out, and thus we will not be able to find recommendations for each of the commercial conditions. For railway we will always have flexible tickets available until the departure is fully sold out. In such situations it is up to the clients to present available products with various conditions that will cover all the travellers for the entire trip or parts of the trip. In these cases the cheapest recommendation can help present an alternative combination of offers. Depending on the offers generated, one may also need to apply some of the flags to get recommendations on supplement products in combination with preassigned fare products, as some products are made in a way that they are supplement products of a preassigned fare product and even tho the preassigned fare product is sold out, the supplement product with the preassigned fare product is still valid. See more about this in the section about `}<em parentName="p">{`flags`}</em></p>
    <h4 {...{
      "id": "flags"
    }}>{`Flags`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`IncludeRecommendedOffersOnly`}</strong>{`: This flag tells offers to only output a subset of offers, equal to offers contained in the recommendations. All other offers are stripped from the response.`}</li>
      <li parentName="ul"><strong parentName="li">{`IncludeUnavailableOffers`}</strong>{`: This flag tells the recommendation algorithm to disregard quotas and seating capacities, however it will favor offers combinations which are available.`}</li>
      <li parentName="ul"><strong parentName="li">{`IncludeMultipleUpgrades`}</strong>{`: This flag lets the recommendation algorithm know that the client supports recommendations which has a combination of supplement products and preassigned fare products. An additional field called `}<em parentName="li">{`"withUpgradeProducts"`}</em>{` is added to the response which contains the supplement products needed to buy in addition to the preassigned fare product. Theese supplement products are just represented as strings and multiple entries of the same supplement product means that the client need to buy the same amount of that same supplement product as there are entries of it.`}</li>
      <li parentName="ul"><strong parentName="li">{`SameTicketChange`}</strong>{`: This flag tells the recommendation algorithm to find combinations of offers where all travellers in the group change tickets on the same service journeys.`}</li>
      <li parentName="ul"><strong parentName="li">{`MixinOffersWithHigherFlexibility`}</strong>{`: This flag tells the recommendation algorithm that offers with higher degree of flexibility can be mixed in, there can be cases where a flexible offer is cheaper than a semi-flexible or non-flexible offer.`}</li>
      <li parentName="ul"><strong parentName="li">{`IncludeOffersWithMixedCommercialConditions`}</strong>{`: This flag tells the recommendation algorithm to include offers where there are multiple preassigned fare products with various flexibility.`}</li>
    </ul>
    <h4 {...{
      "id": "filters"
    }}>{`Filters`}</h4>
    <p>{`To account for different needs among clients, a set of filters can be used. Generally, the filters will do such that the offers which doesn't comform to given filters are not considered while giving recommendations. We offer filtering on the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`IncludeAuthorities`}</strong>{`: Used if a client only wants recommendations for a specific operator or set of operators`}</li>
      <li parentName="ul"><strong parentName="li">{`IncludeFareClasses`}</strong>{`: Used when adding a dimension for fare classes.`}</li>
      <li parentName="ul"><strong parentName="li">{`IncludeAccommodationFacilitySet`}</strong>{`: Used when adding a dimension for facility types(sleeper, regular seat, etc)`}</li>
    </ul>
    <h3 {...{
      "id": "basic-recommendations"
    }}>{`Basic Recommendations`}</h3>
    <h4 {...{
      "id": "remarks"
    }}>{`Remarks`}</h4>
    <ul>
      <li parentName="ul">{`We will only make a recommendation for a serviceJourney if we can find offers for every traveller provided.`}</li>
      <li parentName="ul">{`serviceJourneysCoveredByOffers will indicate which serviceJourneys we managed to find recommendations for. There will be situations where we will not be able to find recommendations for every serviceJourney (for example sold out situations for one of the rides), therefore serviceJourneysCoveredByOffers is important to consider.`}</li>
      <li parentName="ul">{`For each type of recommendation in wantedRecommendation we will return a recommendation for that type of recommendation. If we can't find any offers covering the travellers for any serviceJourney, then the offersToBuy will be empty.`}</li>
    </ul>
    <h4 {...{
      "id": "examples"
    }}>{`Examples`}</h4>
    <details>
      <summary><b>Input</b></summary>
Following is an example illustrating the most basic input to get recommendations. It is required to provide a recommendationCofig specifying wanted recommendations:
      <pre>{`
{
  "travelers": [
    {
      "id": "Adult-1",
      "userType": "ADULT"
    }
  ],
  "recommendationConfig": {
    "wantedRecommendations": [
      "FLEXIBLE",
      "NON_FLEXIBLE",
      "SEMI_FLEXIBLE",
      "CHEAPEST"
    ]
  }
}
`}</pre>
Filters can also be specified in the recommendationConfig, following is an example illustrating this:
      <pre>{`
{
  "travelers": [
    {
      "id": "Adult-1",
      "userType": "ADULT"
    }
  ],
  "recommendationConfig": {
    "wantedRecommendations": [
      "FLEXIBLE",
      "NON_FLEXIBLE",
      "SEMI_FLEXIBLE",
      "CHEAPEST"
    ],
    "includeFareClasses": [
      "STANDARD_CLASS"
    ],
    "includeAuthorities": [
      "RUT",
      "OPP"
    ],
    "includeUnavailableOffers": false,
    "includeOffersWithMixedCommercialConditions": false,
    "includeMultipleUpgrades": true
  }
}
`}</pre>
    </details>
    <h6 {...{
      "id": ""
    }}></h6>
    <details>
      <summary><b>Output</b></summary>
Following is an example illustrating the output:
      <pre>{`
[
  {
    "tripPatternId": "5cf81068a8d85a00010c7a97",
    "offers": [
      {
        "id": "9c409036-6cf7-414f-919f-2648d2ff9bea"
	...
      },
      {
        "id": "9141a510-d77d-4687-854d-16233ed50de1"
	...
      },
      {
        "id": "5014cb07-1922-42c6-aa39-1a36d94c3d04"
	...
      },
      {
        "id": "b5ae3852-da64-4012-88e9-6afb7ab0bbd8"
	...
      },
      {
        "id": "b56ce489-78fe-44ae-bf98-dbc7fce0c480"
	...
      }
    ],
    "seatingCapacity": [...],
    "recommendations": [
      {
        "serviceJourneysCoveredByOffers": [
          "ENT:ServiceJourney:1-1939-1644",
          "ENT:ServiceJourney:1-5636-733"
        ],
        "typeOfRecommendation": "FLEXIBLE",
        "offersToBuy": [
          {
            "id": "9c409036-6cf7-414f-919f-2648d2ff9bea",
            "numberToBuy": 2,
            "withUpgradeProducts": ["ENT:SupplementProduct:SeatUpgrade"]
          },
          {
            "id": "9141a510-d77d-4687-854d-16233ed50de1",
            "numberToBuy": 5,
            "withUpgradeProducts": ["ENT:SupplementProduct:SeatUpgrade"]

          }
        ]
      },
      {
        "serviceJourneysCoveredByOffers": [],
        "typeOfRecommendation": "SEMI_FLEXIBLE",
        "offersToBuy": []
      },
      {
        "serviceJourneysCoveredByOffers": [
          "ENT:ServiceJourney:1-1939-1644",
          "ENT:ServiceJourney:1-5636-733"
        ],
        "typeOfRecommendation": "NON_FLEXIBLE",
        "offersToBuy": [
          {
            "id": "5014cb07-1922-42c6-aa39-1a36d94c3d04",
            "numberToBuy": 2
            "withUpgradeProducts": []
          },
          {
            "id": "b5ae3852-da64-4012-88e9-6afb7ab0bbd8",
            "numberToBuy": 2
            "withUpgradeProducts": []
          },
          {
            "id": "b56ce489-78fe-44ae-bf98-dbc7fce0c480",
            "numberToBuy": 5
            "withUpgradeProducts": []
          }
        ]
      },
      {
        "serviceJourneysCoveredByOffers": [
          "ENT:ServiceJourney:1-1939-1644",
          "ENT:ServiceJourney:1-5636-733"
        ],
        "typeOfRecommendation": "CHEAPEST",
        "offersToBuy": [
          {
            "id": "9c409036-6cf7-414f-919f-2648d2ff9bea",
            "numberToBuy": 2
            "withUpgradeProducts": []
          },
          {
            "id": "9141a510-d77d-4687-854d-16233ed50de1",
            "numberToBuy": 5
            "withUpgradeProducts": []
          }
        ]
      }
    ]
  }
]
`}</pre>
    </details>
    <h3 {...{
      "id": "layered-recommendations"
    }}>{`Layered Recommendations`}</h3>
    <p>{`Layered recommendations has a more complex structure, however it is fairly simple to understand how they are built up. To get a layered recommendation, a layered recommendation configuration needs to be provided. This configuration contains which categories are wanted and which fare classes are wanted. When the recommendation service handles a layered recommendation configuration it creates a new structure of the offers, consisting of three simple steps:`}</p>
    <ol>
      <li parentName="ol">{`The offers are first grouped by which service journey combination they cover.`}</li>
      <li parentName="ol">{`The offers in each group are divided into the categories wanted(specified in the configuration).`}</li>
      <li parentName="ol">{`The offers are further divided inside each category by the fare classes specified in the configuration.`}</li>
    </ol>
    <p>{`We are then left with a structure of service journey combinations, containing a set of categories, which in turn contains a set of fare classes and offers matching that category and fare class. When having this structure it is really easy to find the cheapest combination of offers for each fare class, and thus for the whole structure.`}</p>
    <h4 {...{
      "id": "remarks-1"
    }}>{`Remarks`}</h4>
    <h5 {...{
      "id": "service-journey-organize-algorithm"
    }}>{`Service Journey Organize Algorithm`}</h5>
    <p>{`This is a property in the configuration for layered recommendations. This property is available to provide the clients with different options for how the layered recommendations are generated. There are three algorithms to choose from, which greatly change how many combinations of service journeys covered are generated.`}</p>
    <p>{`Before describing the algorithms in more detail, it is expendient to create a base example from which the algorithms can be explained.`}</p>
    <p>{`Given a trip containing three service journeys; `}<strong parentName="p">{`SJ-1, SJ-2, SJ-3`}</strong>{`, offers generates 5 offers;`}</p>
    <ol>
      <li parentName="ol">{`Offer-1: Covers SJ-1`}</li>
      <li parentName="ol">{`Offer-2: Covers SJ-2`}</li>
      <li parentName="ol">{`Offer-3: Covers SJ-3`}</li>
      <li parentName="ol">{`Offer-4: Covers SJ-1 + SJ-2`}</li>
      <li parentName="ol">{`Offer-5: Covers SJ-1 + SJ-2 + SJ-3`}</li>
    </ol>
    <p>{`The three algorithms and their impact are as follows:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`SUBSEQUENT_COMBINATIONS`}</strong>{` - If this is selected one will create all possible variations of subsequent service journeys and create recommendations for each. Following the example above this will generate these combinations: `}<em parentName="li">{`(SJ-1), (SJ-2), (SJ-3), (SJ-1 + SJ-2), (SJ-2 + SJ-3) and (SJ-1 + SJ-2 + SJ-3)`}</em></li>
      <li parentName="ol"><strong parentName="li">{`FOR_EACH_AND_GROUPED_COMBINATIONS`}</strong>{` - If this is selected each service journeys will become a combination and all of them grouped will become a combinations yielding: `}<em parentName="li">{`(SJ-1), (SJ-2), (SJ-3), (SJ-1 + SJ-2 + SJ-3)`}</em></li>
      <li parentName="ol"><strong parentName="li">{`COMBINATIONS_FROM_OFFERS`}</strong>{` - If this is selected each unique service journey combination found from the generated offers are made a combination. Given the example above this would yield: `}<em parentName="li">{`(SJ-1), (SJ-2), (SJ-3), (SJ-1 + SJ-2), (SJ-1 + SJ-2 + SJ-3)`}</em></li>
    </ol>
    <h5 {...{
      "id": "same-ticket-change"
    }}>{`Same Ticket Change`}</h5>
    <p>{`This is a property in the configuration for layered recommendations which tells recommendations wheather recommendations should find offers where all travellers must change tickets at the same service journeys or not. Given an example where there are two travellers; one adult(`}<em parentName="p">{`A1`}</em>{`) and one student(`}<em parentName="p">{`S1`}</em>{`), there are two service journeys and offers has generated the following offers:`}</p>
    <ol>
      <li parentName="ol">{`Offer-1: Covers A1 for service Journey 1(SJ-1) with a price of 50NOK`}</li>
      <li parentName="ol">{`Offer-2: Covers A1 for service Journey 1(SJ-2) with a price of 50NOK`}</li>
      <li parentName="ol">{`Offer-3: Covers A1 for service Journey 1(SJ-1 + SJ-2) with a price of 70NOK`}</li>
      <li parentName="ol">{`Offer-4: Covers S1 for service Journey 1(SJ-1) with a price of 20NOK`}</li>
      <li parentName="ol">{`Offer-5: Covers S1 for service Journey 1(SJ-2) with a price of 20NOK`}</li>
      <li parentName="ol">{`Offer-6: Covers S1 for service Journey 1(SJ-1 + SJ-2) with a price of 90NOK`}</li>
    </ol>
    <p>{`The cheapest combination of offers in this example is if the travellers bought `}<em parentName="p">{`Offer-3 + Offer-4 + Offer-5`}</em>{`, however if same ticket change is set to true(meaning each traveller would have to change tickets at the same service journeys) the cheapest combination possible would be if the travellers bought `}<em parentName="p">{`Offer-1 + Offer-2 + Offer-4 + Offer-5`}</em>{`.`}</p>
    <h5 {...{
      "id": "any_class"
    }}>{`ANY_CLASS`}</h5>
    <p>{`Some clients might also want to get a layered recommendation without regards to the fare classes, this can be achieved by providing the `}<em parentName="p">{`ANY_CLASS`}</em>{` fare class in the layered recommendation config.`}</p>
    <h4 {...{
      "id": "examples-1"
    }}>{`Examples`}</h4>
    <details>
      <summary><b>Input</b></summary>
      <pre>{`
{
  "typesOfRecommendation": [
    "FLEXIBLE",
    "SEMI_FLEXIBLE"
  ],
  "fareClasses": [
    "PREMIUM_CLASS",
    "STANDARD_CLASS"
  ],
  "includeMultipleUpgrades": true
}
`}</pre>
    </details>
    <h6 {...{
      "id": "-1"
    }}></h6>
    <details>
      <summary><b>Output</b></summary>
      <pre>{`
{
  "tripPattern": {...},
  "tripPatternRaw": {...},
  "offers": [
    { "id": "1", ...},
    { "id": "2", ...},
    { "id": "3", ...},
    { "id": "4", ...},
    { "id": "5", ...},
    { "id": "6", ...},
    { "id": "7", ...},
    { "id": "8", ...},
    { "id": "9", ...},
    { "id": "10", ...},
    { "id": "11", ...},
    { "id": "12", ...}],
  "recommendations": [],
  "layeredRecommendations": [
    {
      "serviceJourneysCovered": [
        "SJ-1"
      ],
      "recommendations": [
        {
          "typeOfRecommendation": "FLEXIBLE",
          "perFareClass": [
            {
              "fareClass": "PREMIUM_CLASS",
              "offersToBuy": [
                {
                  "id": "1",
                  "numberToBuy": 1
                  "withUpgradeProducts": []
                }
              ]
            },
            {
              "fareClass": "STANDARD_CLASS",
              "offersToBuy": [
                {
                  "id": "2",
                  "numberToBuy": 1
                  "withUpgradeProducts": []
                }
              ]
            }
          ]
        },
        {
          "typeOfRecommendation": "SEMI_FLEXIBLE",
          "perFareClass": [
            {
              "fareClass": "PREMIUM_CLASS",
              "offersToBuy": []
            },
            {
              "fareClass": "STANDARD_CLASS",
              "offersToBuy": [
                {
                  "id": "3",
                  "numberToBuy": 1
                  "withUpgradeProducts": []
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "serviceJourneysCovered": [
        "SJ-2"
      ],
      "recommendations": [
        {
          "typeOfRecommendation": "FLEXIBLE",
          "perFareClass": [
            {
              "fareClass": "PREMIUM_CLASS",
              "offersToBuy": []
            },
            {
              "fareClass": "STANDARD_CLASS",
              "offersToBuy": [
                {
                  "id": "4",
                  "numberToBuy": 1
                  "withUpgradeProducts": []
                }
              ]
            }
          ]
        },
        {
          "typeOfRecommendation": "SEMI_FLEXIBLE",
          "perFareClass": [
            {
              "fareClass": "PREMIUM_CLASS",
              "offersToBuy": [
                {
                  "id": "5",
                  "numberToBuy": 1
                  "withUpgradeProducts": ["ENT:SupplementProduct:SeatUpgrade"]
                }
              ]
            },
            {
              "fareClass": "STANDARD_CLASS",
              "offersToBuy": []
            }
          ]
        }
      ]
    },
    {
      "serviceJourneysCovered": [
        "SJ-1",
        "SJ-2"
      ],
      "recommendations": [
        {
          "typeOfRecommendation": "FLEXIBLE",
          "perFareClass": [
            {
              "fareClass": "PREMIUM_CLASS",
              "offersToBuy": [
                {
                  "id": "6",
                  "numberToBuy": 1
                  "withUpgradeProducts": ["ENT:SupplementProduct:SeatUpgrade"]
                }
              ]
            },
            {
              "fareClass": "STANDARD_CLASS",
              "offersToBuy": [
                {
                  "id": "7",
                  "numberToBuy": 1
                  "withUpgradeProducts": []
                }
              ]
            }
          ]
        },
        {
          "typeOfRecommendation": "SEMI_FLEXIBLE",
          "perFareClass": [
            {
              "fareClass": "PREMIUM_CLASS",
              "offersToBuy": []
            },
            {
              "fareClass": "STANDARD_CLASS",
              "offersToBuy": [
                {
                  "id": "8",
                  "numberToBuy": 1
                  "withUpgradeProducts": []
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "seatingCapacity": [...]
}
`}</pre>
    </details>
    <h3 {...{
      "id": "multidimensional-recommendations"
    }}>{`Multidimensional Recommendations`}</h3>
    <p>{`Multidimensional recommendations has a sligthly more complex structure than the layered recommendations. In order to create multidimensional recommendations a multidimensional recommendation config is needed to be specified. This configuration contains two groups of specifications, namely dimensionspec and rulespec. The dimension spec contains information about which dimensions are wanted; which servicejourneyOrganizeAlgorithm, which types of recommendations(flexible, semi-flexible, etc), which fare classes and which accommodation facility types are wanted to be included. The rule spec contains information about which flags are set(see section above about flags). Depending on what is specified the following steps are executed to create the wanted dimensions:`}</p>
    <ol>
      <li parentName="ol">{`The offers are first grouped by which service journey combination they cover.`}</li>
      <li parentName="ol">{`The offers in each group are divided into the categories wanted(specified in the configuration).`}</li>
      <li parentName="ol">{`The offers are further divided inside each category by the fare classes, specified in the configuration.`}</li>
      <li parentName="ol">{`The offers are lastly divided inside each fare class by accommodation facility, specified in the configuration.`}</li>
    </ol>
    <p>{`We are then left with a structure of service journey combinations, containing a set of categories, which in turn contains a set of fare classes, and then lastly the accommodation facilities which contains the matching offers for that category, fare class and accommodation facility. When having this structure it is really easy to find the cheapest combination of offers for each accommodation inside each fare class and each recommendation type.`}</p>
    <h4 {...{
      "id": "remarks-2"
    }}>{`Remarks`}</h4>
    <p>{`See remarks for layered recommendations.`}</p>
    <h5 {...{
      "id": "any_facility_set"
    }}>{`ANY_FACILITY_SET`}</h5>
    <p>{`Works like the ANY_CLASS for fare classes(see section about layered recommendations), just that it is used for accommodation facilities.`}</p>
    <h4 {...{
      "id": "examples-2"
    }}>{`Examples`}</h4>
    <details>
      <summary><b>Input</b></summary>
      <pre>{`
{
  "multidimensionalRecommendationConfig": {
    "dimensionSpec": {
      "serviceJourneyOrganizeAlgorithm": "FOR_EACH_AND_GROUPED_COMBINATIONS",
      "includeTypesOfRecommendations": ["FLEXIBLE"],
      "includeFareClasses": ["PREMIUM_CLASS"],
      "includeAccommodationFacilitySet": ["SLEEPER", "SEATING"]
    },
    "ruleSpec": {
      "sameTicketChange": false,
      "includeUnavailableOffers": false,
      "mixinOffersWithHigherFlexibility": true,
      "includeMultipleUpgrades": true
    }
  }
}
`}</pre>
    </details>
    <h6 {...{
      "id": "-2"
    }}></h6>
    <details>
      <summary><b>Output</b></summary>
      <pre>{`
{
  "tripPattern": {...},
  "tripPatternRaw": {...},
  "offers": [
    { "id": "1", ...},
    { "id": "2", ...},
    { "id": "3", ...},
    { "id": "4", ...},
    { "id": "5", ...},
    { "id": "6", ...},
    { "id": "7", ...},
    { "id": "8", ...},
    { "id": "9", ...},
    { "id": "10", ...},
    { "id": "11", ...},
    { "id": "12", ...}],
  "recommendations": [],
  "multidimensionalRecommendations": [
    {
      "serviceJourneysCovered": ["SJ-1"],
      "perTypeOfRecommendation": [
        {
          "typeOfRecommendation": "FLEXIBLE",
          "perFareClass": [
            {
              "fareClass": "PREMIUM_CLASS",
              "perFacilitySet": [
                {
                  "facilitySet": "SLEEPER",
                  "offersToBuy": [
                    {
                      "id": "714a1498-2e78-48ca-8ff4-2bba3ce51b79",
                      "numberToBuy": 1,
                      "withUpgradeProducts": ["ENT:SupplementProduct:Sleeper"]
                    }
                  ]
                },
                {
                  "facilitySet": "SEATING",
                  "offersToBuy": []
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "seatingCapacity": [...]
}
`}</pre>
    </details>
    <h2 {...{
      "id": "tagspec---deprecated-supported-until-november-1-2019"
    }}>{`TagSpec - Deprecated (supported until November 1. 2019)`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <h3 {...{
      "id": "introduction-1"
    }}>{`Introduction`}</h3>
    <p>{`The Offers-API will find every valid offer for a given trip and list of travelers, and finding a combination of offers that covers the whole trip for each traveler may be a challenging task in some cases, especially if you are searching for a specific combination like the cheapest one.`}</p>
    <p>{`We have created a tagging mechanism to help our consumers select the optimal offers combination for any trip. The idea is to let the client specify what kind of combinations thay are interested in, and give all offers within that combination the same tag. You find the option to specify which tags you want to use in the tagSpec input parameter.`}</p>
    <h3 {...{
      "id": "the-different-tagging-options"
    }}>{`The different tagging options`}</h3>
    <p>{`We have created several different tag options so far, which can be used in combination with each other. This means that any offer can have zero, one or several tags.`}</p>
    <ul>
      <li parentName="ul">{`tagCheapest: This tag will mark the combination of offers that is needed in order to achieve the lowest price for the entire group of travellers.`}</li>
      <li parentName="ul">{`tagPrFareClass: We have a classification of fareClass which includes “Economy”, “Standard” and “Premium”, this tag will find the optimal combination (cheapest) of offers within each of these classes.
sameTicketChangeForAllTravelers: This will ensure that all travellers in a party will change tickets at the same time in trips where we have several legs.`}</li>
    </ul>
    <h3 {...{
      "id": "how-to-include-offers-without-a-tag"
    }}>{`How to include offers without a tag`}</h3>
    <p>{`Most of our consumers use the tagging mechanism, and will ignore any offer without a tag. In order to reduce the amount of data we return we will by default filter out any offer that does not contain a tag. This will only apply if a client have specified at least one tag.`}</p>
    <p>{`If you want to use the tagging mechanism without this filtering method you can override it by declaring “includeOffersWithoutTag” as true in the tagSpec specification.`}</p>
    <h2 {...{
      "id": "the-structure-of-an-offer"
    }}>{`The structure of an offer`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`An Offer is the offering of a product at a specific price, often related to a trip and a traveler, but not neccessarely. If a trip and a traveler is given the offer is made by finding a sales package configuration that is valid for the given trip and traveler and computing the price for that configuration. It is also possible to omit the trip and traveler and specify the parameters that should be used for the sales package configuration directly. More on sales package configurations below.`}</p>
    <h3 {...{
      "id": "model"
    }}>{`Model`}</h3>
    <p>{`The offer consists of 3 parts, the sales package configuration, the traveler validity map and the tags. The first two parts will be explained here, while the last part has its own section on this page.`}</p>
    <h4 {...{
      "id": "sales-package-configuration"
    }}>{`Sales package configuration`}</h4>
    <p><img alt="Offers model" src={require("./offers-model.png")} /></p>
    <p>{`The sales package configuration is the main part of the offer. To explain what it is properly it is neccessary to explain a few things about the product model. I the model each product is split into different smaller entities. Each entity models a separate set of the products properties and rules. There are 4 main entities: sales package, fare product, validable element, fare structure element (these will hereafter often be refered to with SP, FP, VE, FSE).`}</p>
    <p><em parentName="p">{`Sales package`}</em>{`: Concerned with the rules regarding distribution of the product.`}</p>
    <p><em parentName="p">{`Fare product`}</em>{`: Concerned with the rules regarding different usages of the product (how its refunded, if it can be exchanged etc).`}</p>
    <p><em parentName="p">{`Validable element`}</em>{`: Concerned with the rules regarding where and when the product is valid.`}</p>
    <p><em parentName="p">{`Fare structure element`}</em>{`: Concerned with the pricing of the product. *`}</p>
    <p>{`These entities are arranged into a hierarchy as shown in the figure to the right. What this means is that a VE can consist of one or more FSEs. An FP can consist of one or more VEs. And an SP can consist of one or more FPs. Common for all 4 entities is that they can be linked to parameters. The parameters are the entities that express the different rules. For example there is one parameter named UserProfile that when linked to a product expresses what types of travellers are allowed to use that product.`}</p>
    <p>{`The parameters can be linked to a price, and by doing that they can be used to express the fare structure of a product. There will typically be a separate parameter for each differentiable step in the fare structure. For example a product a product that cost 50 kr for traveling accross 1 or 2 zones, and 70 for longer travels may be modelled with an FSE linked to 2 separate GeographicalIntervals.`}</p>
    <p>{`Now back to the sales package configuration. A sales package configuration is a sales package where only a specific subset of the parameters are used. The product definition may contain multiple user profiles, but in a sales package configuration only the profile that is relevant for the current traveller is used. The sales package configuration follows the same structure as the model, meaning that
it contains a fare product configuration for each valid product in the sales package, and so forth. Each configuration contains a reference to the entity they are based on and a ref to each of the chosen parameters if there are any. These references can be used to fetch the complete entity using the Prodducts-API if more information is needed.`}</p>
    <p>{`*Even though each of the 4 main entities have a separate responsibility so to say, the model allows for almost all types of parameters to be linked to anyone of them (the exception is GeographicalInterval and other parameters directly related to the fare structure. Those parameters can only be linked to an FSE). This leads to the definitions of these entities to become a bit blurry sometimes.`}</p>
    <h4 {...{
      "id": "traveller-mapping"
    }}>{`Traveller-mapping`}</h4>
    <p>{`The travellerMapping field on the offer says which travellers are allowed to use the offer, and what user profile they received. It contains a list of TravellerValidityGroups and each group has a user profile reference and a list of traveller ids. There may be more than one TravellerValidityGroup if the offer is for a group ticket, or if the chosen user profile has a companion profile. For example for a family ticket for one child and one adult, there will be two TravellerValidityGroups in the traveller mapping. The one for adult will have a reference to the adult user profile and the ids of all the travellers that are considered adults according to that user profile.`}</p>
    <p>{`The traveller validity group also indicate the max and min nr of travellers within that group that one offer can cover. This can be used to find the number of offers needed to cover all the travellers. If the client asks for the offers to be taged, these numbers will be used in that calculation.`}</p>
    <h3 {...{
      "id": "pricing"
    }}>{`Pricing`}</h3>
    <p>{`The price of the offer is the price of the sales package configuration. Each parameter and sub-configuration may contribute to the price. For example a 50% child-discount will typically be shown as a negative price contribution on the user profile for child. In the configuration-hierarchy the prices are aggregated meaning that the price of the SP-configuration will include the sum of the prices for all of its FP-configurations and the parameters linked to the SP-configuration. If a configuration has a price contribution it wil also be included in the aggregate.`}</p>
    <h3 {...{
      "id": "multiple-legs"
    }}>{`Multiple legs`}</h3>
    <p>{`An offer may cover more than just one leg if the product allowes for interchanging. For some products the offers will include a configuration for the lower-level-entities (ref the figure) for each of the legs. For a trip with two legs there may for example one FSE-configuration for each leg of the trip, while still only beeing one VE-configuration. The split happens at the layer that contains the Interchanging-parameter. This can be useful if its neccessary to distribute the revenue accross the legs as it is already correcly calculated in the offer. Each configuration contains a list of service journey id to indicate which part of the total trip they are relatet to. A configuration higher up in the hierarchy will always point to the combined list of service journeys that its sub-configurations point to.`}</p>
    <h3 {...{
      "id": "optional-products"
    }}>{`Optional products`}</h3>
    <p>{`Some sales packages may include supplement products along with the main product. The optional field on fare product configuration is used to indicate that the customer can choose whether or not they want to purchase that product when they buy the offer. The price of an optional product will not be included in the price of the sales package configuration.`}</p>
    <h2 {...{
      "id": "supplement-products"
    }}>{`Supplement products`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`A supplement product is a fare product that will provide an additional access right when used with another fare product. The additional right could be e.g. a seat reservation, an upgrade to another class, or a meal. Supplement products are by nature optional, which means that the customer can choose if they want to include them in the purchase or not. Supplement product also usually means supplement price, although they may be offered for free.`}</p>
    </blockquote>
    <p>{`Supplement products usually have specific requirements with regards to which fare products that will qualify for offering the supplement product. As an example, a public transport operator may want to offer a supplement product for a meal on economy fares, while the standard and premium fares include the meal. Consequently, it is only relevant to present the meal supplement product for the economy fare. The meal supplement requires the consumer to be present on the train, so it is also not possible to buy the supplement product without the economy fare product that grants access to the ride. The requirements for offering a supplement product are checked by the fare calculation engine and only if all requirements are met will the supplement product be offered. The fare product and the supplement product are usually sold as a whole, meaning they are represented by two sales offer package elements in the same sales offer package. If the customer already purchased an access right (fare product) that entitles a supplement product, the pre-purchased fare product must be specified for each traveler when requesting an offer in order to have the supplement product presented as a separate offer.`}</p>
    <h3 {...{
      "id": "the-price-of-a-supplement-product"
    }}>{`The price of a supplement product`}</h3>
    <p>{`The price of an optional supplement product is not included in the price that is reported on the sales offer package. The price of the sales offer package covers all required products in the package. To find the total price for a sales offer package with a specific choice of supplement products, combine the price of each supplement product that should be included with the price of sales offer package itself.`}</p>
    <h3 {...{
      "id": "common-supplement-products-and-classification-of-supplement-products"
    }}>{`Common supplement products and classification of supplement products`}</h3>
    <p>{`Some common supplement products are listed below:`}</p>
    <ul>
      <li parentName="ul">{`Supplement products that represent seat reservations`}</li>
      <li parentName="ul">{`Supplement products for sundry items like the right to bring extra luggage, bicycles or pets`}</li>
      <li parentName="ul">{`Special products like reservations for large motorized wheelchairs`}</li>
      <li parentName="ul">{`Supplement products that represent different classes of seat reservations are usually mutually exclusive when considering one ride on public transport. If a PTO wishes to support several types of reservation products that the customer may choose from (e.g. standard seat, premium seat, seat in family area) these supplement products can be typed using the TypeOfFareProduct mechanism. Supplement products with the same TypeOfFareProduct can then be grouped together when the offers are presented to the customer. The TypeOfFareProduct property is common to all FareProducts and is inherited by SupplementProduct.`}</li>
    </ul>
    <p>{`Supplement products will usually have parameters describing the access right granted by the product. An example would be a supplement product granting the right to bring a bicycle, which will typically have a Luggage Allowance parameter that specifies the baggage type bicycle.`}</p>
    <p>{`In rare cases a supplement product can be modelled as a group ticket. This would be the case if a supplement product is used to reserve a whole compartment, e.g. an optional  sleeper compartment on a night train which can be used by 1-4 persons. The minimum and maximum number of persons is modelled by adding a group ticket parameter to the supplement product. Note that unlike group ticket properties on PreassignedFareProducts, SupplementProducts with group parameters do not influence the contents of the travellerMapping section or the numberToBuy in the tags section of the response. As the SupplementProduct is optional, the customer will have to make a choice on how many of the SupplementProduct that should be included in the purchase.`}</p>
    <h3 {...{
      "id": "capacity-for-supplement-products"
    }}>{`Capacity for supplement products`}</h3>
    <p>{`Supplement products often have limited availability, typically when there are limited number of seats/compartments or physical space for luggage onboard. Information regarding the current availability for supplement products with limited availability are included in the seatingCapacity part of the response when requesting offers. See also Seating Capacity.`}</p>
    <p>{`The following table describes some common availability scenarios for supplement products:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Response data`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Interpretation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SupplementProduct A from a SalesOfferPackage for DatedServiceJourney 123 is included in the seatingCapacity list with capacity X where X>0 for DatedServiceJourney 123`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SupplementProduct A is available, with X left to sell on the ride identified by DatedServiceJourney 123`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SupplementProduct A from a SalesOfferPackage for DatedServiceJourney 123 is included in the seatingCapacity list with capacity =0 for DatedServiceJourney 123`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SupplementProduct A is sold out on the ride identified by DatedServiceJourney 123`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SupplementProduct A from a SalesOfferPackage for DatedServiceJourney 123 is included in the seatingCapacity list, but capacity and/or DatedServiceJourney is not listed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An error occurred when checking availability, which means A cannot be sold temporarily. A could become available at a later time.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SupplementProduct B from a SalesOfferPackage from DatedServiceJourney 234 is not included in the seatingCapacity list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Product B has no availability limitations and is always available on the ride identified by DatedServiceJourney 234`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "supplement-products-that-apply-to-more-than-one-leg"
    }}>{`Supplement products that apply to more than one leg`}</h3>
    <p>{`Supplement products are usually purchased separately for each ride in a trip. This allows the customer to choose different supplements for the different rides. However, in some cases a supplement product can cover two or more rides. Similar to other fare products, the serviceJourneyIds list in the fareProducts section of the response specifies which rides the supplement product apply to.`}</p>
    <h3 {...{
      "id": "filtering-supplementproducts"
    }}>{`Filtering SupplementProducts`}</h3>
    <p>{`In order to reduce the number of SupplementProducts in a response, the API will by default omit SupplementProducts for luggage. To include SupplementProducts for luggage, specify the type of luggage to include in the luggageTypes section of Traveler in the request.`}</p>
    <h3 {...{
      "id": "selecting-supplement-products"
    }}>{`Selecting supplement products`}</h3>
    <p>{`Each FareProduct included in a Sales Offer Package has a unique selectableId. For optional products like SupplementProducts, use the offerId in combination with the selectableId to identify and order the SupplementPoduct in the sales and reservation APIs.`}</p>
    <p>{`Note: If a trip consisting of several rides do not offer the same type of luggage for all rides (either as a parameter on the fare product describing included access rights or as a separate supplement), the customer may not be able to buy any combination of products that provides them the necessary access rights to bring the luggage on the entire journey. The clients of the offer API should pay attention to such scenarios and try to inform and guide the customer to make the best possible choice in such situations.`}</p>
    <h2 {...{
      "id": "seating-capacity"
    }}>{`Seating capacity`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Seating capacity shows the number of available seats for fare products that require seat reservation for a given departure. However, it is worth noting that seating capacity is indicative and does not contain a complete list of capacity. It is only a snapshot of capacities required for the offers retrieved. A product is present in the seating capacity response if the product configuration has specified that it has compulsory reservation or if there are quotas specifying that a product needs to be reserved. Clients can assume that every item in the list of seating capacity needs to be reserved. An element in seating capacity has the following fields:`}</p>
    <ul>
      <li parentName="ul">{`fareProductId: the id of the fare product`}</li>
      <li parentName="ul">{`datedServiceJourneyId: a unique id for the departure`}</li>
      <li parentName="ul">{`capacity: the sum of available seats for the fare product for this departure`}</li>
      <li parentName="ul">{`status: OPEN/CLOSED/UNKNOWN`}</li>
    </ul>
    <p>{`Seating capacity can be fetched from three endpoints:`}</p>
    <ul>
      <li parentName="ul">{`POST /search/trip`}</li>
      <li parentName="ul">{`GET /search/trip/trip-pattern/<tripId`}{`>`}</li>
      <li parentName="ul">{`PATCH /search/trip/trip-pattern/<tripId`}{`>`}</li>
    </ul>
    <h4 {...{
      "id": "examples-3"
    }}>{`Examples`}</h4>
    <details>
      <summary><b>Typical</b></summary>
In this example, the products with compulsory reservation are Ticket, SeatUpgrade and OtherTicket. The seating capacity is for one two legs:
      <pre>{`
"seatingCapacity": [
   {
      "fareProductId": "ENT:PreassignedFareProduct:Ticket",
      "datedServiceJourneyId": "ENT:DatedServiceJourney:5650328",
      "capacity": 25,
      "status": "OPEN"
   },
   {
      "fareProductId": "ENT:SupplementProduct:SeatUpgrade",
      "datedServiceJourneyId": "ENT:DatedServiceJourney:5650328",
      "capacity": 0,
      "status": "OPEN"
   },
   {
      "fareProductId": "ENT:PreassignedFareProduct:OtherTicket",
      "datedServiceJourneyId": "ENT:DatedServiceJourney:5650328",
      "capacity": 0
      "status": "UNKNOWN"
   },
   {
      "fareProductId": "ENT:PreassignedFareProduct:Ticket",
      "capacity": 0,
      "status": "UNKNOWN"
   }
]
`}</pre>
The first three elements are for the same leg, and capacity was found for Ticket and SeatUpgrade(sold out), however no information was found about OtherTicket, which is why the status is UNKNOWN. The fourth element is for the second leg, but no dated service journey id was found. Offers isn’t able to find capacity without a dated service journey id.
    </details>
    <h6 {...{
      "id": "-3"
    }}></h6>
    <details>
      <summary><b>Closed departures</b></summary>
Another case which can occur is closed departures, this means that the operator have closed the departure and nothing should be sold on that departure. The capacity would look something like this:
      <pre>{`
"seatingCapacity": [
   {
      "fareProductId": "ENT:PreassignedFareProduct:Ticket",
      "datedServiceJourneyId": "ENT:DatedServiceJourney:5650328",
      "capacity": 0,
      "status": "CLOSED"
   },
   {
      "fareProductId": "ENT:SupplementProduct:SeatUpgrade",
      "datedServiceJourneyId": "ENT:DatedServiceJourney:5650328",
      "capacity": 0,
      "status": "CLOSED"
   },
   {
      "fareProductId": "ENT:SupplementProduct:OtherTicket",
      "datedServiceJourneyId": "ENT:DatedServiceJourney:5650328",
      "capacity": 0,
      "status": "CLOSED"
   },
   {
      "fareProductId": "",
      "datedServiceJourneyId": "ENT:DatedServiceJourney:5650328",
      "capacity": 0,
      "status": "CLOSED"
   },
]
`}</pre>
    </details>
    <h2 {...{
      "id": "quota"
    }}>{`Quota`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Quota is the remaining availability for a given serviceJourney, it is generated based on the Stock object, see here, which is collected from the Inventory module. It is comprimised of two fields:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`serviceJourneyId`}</strong>{`: which is the id of the relevant service journey for which the quota is appliccable.`}</li>
      <li parentName="ul"><strong parentName="li">{`stock`}</strong>{`: is the remaining availability of the element, or rather the product for which it is located under. It is generated by the aggregatedAvailability property of the Stock object, and thus shows the total availability including e.g. overflow.`}</li>
    </ul>
    <details>
      <summary><b>An example for how the quota object could be represented in the offer response</b></summary>
      <pre>{`
"fareStructureElements": [
    {
        "id": "ENT:FareStructureElement:KMInterval",
        "version": "ENT:Version:1",
        .
        .
        .
        "serviceJourneyIds": [
            "ENT:ServiceJourney:123"
        ],
        "datedServiceJourneys": [],
        "interval": {
            "id": "ENT:GeographicalInterval:100KM",
            "version": "ENT:Version:1",
            "name": [],
            "description": [],
            "distance": 100,
            "zones": []
        },
        "quotas": [
            {
                "serviceJourneyId": "ENT:ServiceJourney:123",
                "stock": 10
            }
        ]
    }
]
`}</pre>
    </details>
    <h2 {...{
      "id": "entitlement-products"
    }}>{`Entitlement products`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Entitlement products are products that can give travellers certain benefits when buying or consuming other products.
Users of the Offers-API can specify that a traveller owns an entitlement product by providing the id of the entitlement
product in the `}<inlineCode parentName="p">{`PreownedProductSpec`}</inlineCode>{`-attribute on `}<inlineCode parentName="p">{`Traveler`}</inlineCode>{`.`}</p>
    <details>
      <summary><b>Example</b></summary>
      <pre>{`
"travelers": [
    {
        "id": "traveler1",
        "userType": "ADULT"
        "products": [
            {
                "id": "ENT:EntitlementProduct:CustomerCard"
            }
        ]
    }
]
`}</pre>
    </details>
    <h6 {...{
      "id": "-4"
    }}></h6>
    <p>{`In order for an entitlement product to give a discount to another product there must exist a sale discount right that is
linked to the entitlement product and to the main product. Sale discount rights are separate products that must be valid
for the specified trip and the traveller holding the entitlement product for it to be applied to the main products.
If a sale discount right has been applied to a product, then the fare product configuration will contain information
about the the sale discount right under the field `}<inlineCode parentName="p">{`FareProductConfiguration.discountRight`}</inlineCode>{`. The price on the sale
discount right configuration is is the total discount given. This amount will be included the price of the main product.`}</p>
    <p>{`Information on how to find entitlement products for a traveler can be found in the documentation for the Benefits-API: `}<a parentName="p" {...{
        "href": "https://developer.entur.org/pages-benefits-docs-benefits-api"
      }}>{`https://developer.entur.org/pages-benefits-docs-benefits-api`}</a></p>
    <details>
      <summary><b>Example of an Offer with an applied sale discount right</b></summary>
      <pre>{`
{
  "id": "aabe8409-b439-47a4-8a8d-db2b92ee1023",
  "salesPackageConfig": {
    "id": "ENT:SalesPackage:Flex",
    "version": "ENT:Version:SP-Flex-1",
    "prices": [
      {
        "amount": "0", // Total price for the offer
        "currency": "NOK"
      }
    ],
    "priceContributions": [
      {
        "amount": "0.00",
        "currency": "NOK"
      }
    ],
    ...,
    "fareProducts": [
      {
        "id": "ENT:PreassignedFareProduct:Flex",
        "version": "ENT:Version:PFP-Flex-1",
        "prices": [
          {
            "amount": "0.00", // Total price for the main product, including the discount from the sale discount right
            "currency": "NOK"
          }
        ],
        "priceContributions": [
          {
            "amount": "0.00",
            "vatGroup": "TRANSPORT_AND_TICKETS_VAT",
            "currency": "NOK"
          }
        ],
        "validableElements": [
          {
            "id": "ENT:ValidableElement:Flex",
            ...,
            "prices": [
              {
                "amount": "500.00", // The base price of the product.
                "currency": "NOK"
              }
            ],
          }
        ],
        ...,
        "discountRight": {
          "id": "ENT:SaleDiscountRight:FlexDiscount",
          "version": "V1",
          "prices": [
            {
              "amount": "-500", // Total discount
              "currency": "NOK"
            }
          ],
          "priceContributions": [
            {
              "amount": "0.00",
              "currency": "NOK"
            }
          ],
          "parameters": {
            "entitlementGiven": [
              {
                "id": "ENT:EntitlementGiven:Flex",
                "version": "1",
                "prices": [
                  {
                    "amount": "-531",
                    "currency": "NOK"
                  }
                ],
                "priceContributions": [
                  {
                    "amount": "-531",
                    "currency": "NOK"
                  }
                ],
                "optional": false
              }
            ]
          },
          ...,
        }
      }
    ]
  },
  "travelerMapping": [
    {
      "travelerIds": [
        "a8333352-dec7-4514-a982-91a0660888d7"
      ],
      "maxNumberOfTravelers": 1,
      "minNumberOfTravelers": 1,
      "userProfileId": "ENT:UserProfile:Adult",
      "userProfileIds": []
    }
  ],
  "tags": []
}
`}</pre>
    </details>
    <h2 {...{
      "id": "tickets-for-extended-journeys"
    }}>{`Tickets for extended journeys`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`If a traveller has a valid ticket but wishes to travel further than the original ticket is valid, some operators offer a
ticket for the additional journey at a reduced rate. A specialized endpoint is available that will calculate the necessary
ticket extension, considering the total journey and the ticket that the traveller already has.`}</p>
    <p>{`The most common case is where the active ticket is a period ticket or travel pass, and the extension is a one-time
journey. The extension endpoint will calculate a new offer for an extension even if the active ticket is a single use
ticket. Note that if the active ticket is an exchangeable single use ticket, it is probably more appropriate to offer
the customer guidance on the exchange process rather than offering an extension ticket.`}</p>
    <p>{`Not every operator offers the possibility to buy a reduced rate ticket for extending a journey but rather requires the
customer to buy a normal single ticket for the extension part of the journey. The extension endpoint will produce an
offer for a normal single use ticket as well as a reduced price offer (if any such product exists). In any case the
offers given will provide the best match for the part of the journey for which the customer needs to buy a new ticket.
If there is no product that can cover the total of the requested journey in combination with the active ticket, the
resulting response from the extension endpoint will be empty. To summarize, the extension ticket endpoint will in most
cases deliver 1 or 2 offers, of which the customer may choose the offer with the lowest rate.`}</p>
    <p>{`The extension endpoint is designed to handle only one traveller at the time, but can facilitate offers for several trip
patterns if there are several options for traveling that are of interest to the traveller.`}</p>
    <p>{`The extension endpoint targets travel within the network of a single operator. It is out of scope for the extension
endpoint to provide offers for a trip that involves journeys with multiple transport operators.`}</p>
    <p>{`Please note that an extension ticket do not replace the original ticket. Both tickets are required to document the right
to travel on the entire journey.`}</p>
    <p>{`Extension tickets can be calculated by the endpoint`}</p>
    <ul>
      <li parentName="ul">{`POST /search/extensions/trip`}</li>
    </ul>
    <p>{`The product id and the validity of the existing ticket is described on the traveler.`}</p>
    <p>{`NOTE: In order to compute the correct number of zones for the trip, the tripPattern must include information about the
route traveled represented by points on link information on each leg. See the Journey Planner documentation for more
information on points on link and tripPatterns. Although this is only required for some zone based products we recommend
always including pointsOnLink in the tripPatterns used to request offers.`}</p>
    <details>
      <summary><b>Example</b></summary>
      <pre>{`
"travelers": [
    {
        "id": "traveler1",
        "userType": "ADULT"
        "products": [
            {
                "id": "RUT:PreassignedFareProduct:Ruter30Days",
                "zones": [
                "RUT:TariffZone:2V"
                ]
            }
        ]
    }
]
`}</pre>
    </details>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      